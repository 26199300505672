@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&family=Noto+Serif&family=Roboto:wght@300&display=swap");

body {
  background: #efeff5;
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-right: none;
  border-left: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}
/* .vertical-timeline-element {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} */

.vertical-timeline-element-content {
  padding: 0 !important;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  cursor: pointer;
}

.vertical-timeline-element-date {
  margin-top: 2px !important;

  @media (max-width: 1170px) {
    margin-left: 1rem;
  }
}

.yarl__container {
  border-radius: 6px !important;
}
